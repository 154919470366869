@import './fonts.css';
@import './reach-skip-nav.css';
@import './reach-dialog.css';

/* purgecss start ignore */
@tailwind base;
/* purgecss end ignore */

@tailwind components;
@tailwind utilities;
@tailwind screens;

:root {
    --reach-tabs: 1;
}

html {
    font-size: 17px;
    line-height: 1.18;
}

body {
    @apply font-body;
}

/*
Focus color for tailwind-forms has to be overridden by CSS.
See https://github.com/tailwindlabs/tailwindcss-forms/issues/52
*/
@layer base {
    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        outline: none;
        box-shadow: none;
        border-width: 0;
        border-bottom: 2px solid theme('colors.primary.500');
        background-color: transparent;
    }


    [type='checkbox'],
    [type='radio'] {
        color: theme('colors.primary.500');
    }

    [type='checkbox']:focus,
    [type='radio']:focus {
        --tw-ring-color: theme('colors.primary.500');
    }

    button:focus,
    a:focus {
        box-shadow: 0 0 0 3px theme('colors.primary.500');
        border-color: theme('colors.primary.500');
        outline: none;
    }
}
